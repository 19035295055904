import React, { useEffect, useState, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import { postData, getData } from '../services/RequestWrapper';

function Periods() {
	const [showModalAdd, setShowModalAdd] = useState(false);
	const [dateFrom, setDateFrom] = useState(new Date().toJSON().slice(0, 10));
	const [dateTo, setDateTo] = useState(new Date().toJSON().slice(0, 10));
	const [description, setDescription] = useState('');
	
	const [periods, setPeriods] = useState([]);

	const [showModalEdit, setShowModalEdit] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(false);
	const [editDateFrom, setEditDateFrom] = useState(new Date().toJSON().slice(0, 10));
	const [editDateTo, setEditDateTo] = useState(new Date().toJSON().slice(0, 10));
	const [editDescription, setEditDescription] = useState('');

    useEffect(() => {
		getData('/api/config/period/get/all').then(data => {
			setPeriods(data);
		});
    }, [])

	const handleAddPeriod = () => {
		postData('/api/config/period/add', { startDate: dateFrom, endDate: dateTo, description: description }).then(data => {
			periods.push(data);
			const np = periods.map(m => m);
			setPeriods(np);
			setShowModalAdd(false);
		});
	}

	const handleOpenAddDialog = () => () => {
		setEditDateFrom('');
		setEditDateTo('');
		setEditDescription('');
		setShowModalAdd(true);
	}

	const handleOpenEditDialog = (index) => () => {
		setSelectedIndex(index);
		setEditDateFrom(periods[index].startDate);
		setEditDateTo(periods[index].endDate);
		setEditDescription(periods[index].description);
		setShowModalEdit(true);
	}

	const handleClose = () => {
		setShowModalEdit(false);
		setShowModalAdd(false);
	}

	const handleSave = () => {
		postData('/api/config/period/edit', { id: periods[selectedIndex].id, startDate: editDateFrom, endDate: editDateTo, description: editDescription, limit: periods[selectedIndex].limit }).then(data => {
			periods[selectedIndex] = data;
			const np = periods.map(m => m);
			setPeriods(np);
		});
		setShowModalEdit(false);
	}

    return (
		<div>
			<ListGroup>
				<ListGroup.Item key={'pnew'}>
					<div className="row">
						<div className="col-9">
						</div>
						<div className="col-3">
							<Button variant="primary" onClick={handleOpenAddDialog()}>
								Add
							</Button>
						</div>
					</div>
				</ListGroup.Item>
				{periods.map((period, index) => (
					<ListGroup.Item key={'p' + period.id}>
						<div className="row">
							<div className="col-9">
								<span>{period.description}</span><br/>
								<span>{period.startDate} - {period.endDate}</span>		
							</div>
							<div className="col-3">
								<Button variant="primary" onClick={handleOpenEditDialog(index)}>
									Edit
								</Button>
							</div>
						</div>
					</ListGroup.Item>
				))}
			</ListGroup>

			<Modal show={showModalAdd} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Add</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>З</Form.Label>
							<Form.Control
								type="date"
								placeholder="Date From"
								value={dateFrom}
								onChange={(e) => { setDateFrom(e.target.value); }}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>До</Form.Label>
							<Form.Control
								type="date"
								placeholder="Date To"
								value={dateTo}
								onChange={(e) => { setDateTo(e.target.value); }}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Опис</Form.Label>
							<Form.Control placeholder="Назва" aria-label="Назва" type="text" value={description} onChange={e => setDescription(e.target.value)} />
						</Form.Group>
					</Form>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleAddPeriod}>
						Add
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showModalEdit} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Edit</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>З</Form.Label>
							<Form.Control
								type="date"
								placeholder="Date From"
								value={editDateFrom}
								onChange={(e) => { setEditDateFrom(e.target.value); }}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>До</Form.Label>
							<Form.Control
								type="date"
								placeholder="Date To"
								value={editDateTo}
								onChange={(e) => { setEditDateTo(e.target.value); }}
							/>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Опис</Form.Label>
							<Form.Control placeholder="Назва" aria-label="Назва" type="text" value={editDescription} onChange={e => setEditDescription(e.target.value)} />
						</Form.Group>						
					</Form>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleSave}>
						Edit
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
    );
}

export default Periods;