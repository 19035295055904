import { useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { getData, postFormData } from '../../services/RequestWrapper';

import ListGroup from 'react-bootstrap/ListGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from 'react-bootstrap/Form';
import FormatedPrice from '../CashFlow/FormatedPrice';

import './ExpensesTable.css';

const ExpensesTable = forwardRef((props, ref) => {
	const reportType = {
		actualvsplanned: 0,
		actualvstotal: 1
	};

	const [reportData, setReportData] = useState(null);
	const [totalActualExpenses, setTotalActualExpenses] = useState(0);
	const [totalPlannedExpenses, setTotalPlannedExpenses] = useState(0);
	const [totalUncategorizedExpenses, seTtotalUncategorizedExpenses] = useState(0);

	const [cashback, setCashback] = useState(0);
	const [categories, setCategories] = useState([]);

	const [summaryType, setSummaryType] = useState(reportType.actualvsplanned);

	useEffect(() => {
		getData('/api/config/category/get/all').then(data => { setCategories(data); });
	}, [])

    useImperativeHandle(ref, () => ({
		handlePeriodChange(p) {
			postFormData('/api/report/uncategorized/sum', { periodId: p.id }).then(data => { seTtotalUncategorizedExpenses(data); });

			postFormData('/api/report/plans/vs/actual/for/report', { periodId: p.id }).then(data => {
				setTotalActualExpenses(data.reduce((accumulator, object) => { return accumulator + object.actual; }, 0));

				var planned = data.reduce((accumulator, object) => { return accumulator + object.expected; }, 0);
				if (p.limit > 0 && p.limit < planned) {
					setTotalPlannedExpenses(p.limit);
				} else {
					setTotalPlannedExpenses(planned);
				}

				var orderedData = reorderDataBySummaryType(summaryType, data);
				setReportData(orderedData);
			});

			postFormData('/api/report/cashback/for/period', { periodId: p.id }).then(data => {
				setCashback(data);
			});
        }
	}));

	const reorderDataBySummaryType = (e, unorderedData) => {
		var data = e == reportType.actualvsplanned ?
			unorderedData.sort((a, b) => {
				const nameA = a.label.toUpperCase();
				const nameB = b.label.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}

				// names must be equal
				return 0;
			}) : unorderedData.sort((a, b) => b.actual - a.actual);

		return data;
	};

	const handleSummaryTypeChange = (e) => {
		setSummaryType(e.target.value);
		var orderedReport = reorderDataBySummaryType(e.target.value, reportData);
		setReportData(orderedReport);
	};

	const getPlanUsagePercentage = () => {
		if (totalPlannedExpenses == 0) return 0;
		if (totalActualExpenses > totalPlannedExpenses) return 100;
		return Math.round(100 * totalActualExpenses / totalPlannedExpenses);
	}

	const getOverPlanUsagePercentage = () => {
		if (totalPlannedExpenses == 0) return 0;
		if (totalActualExpenses < totalPlannedExpenses) return 0;
		return Math.round(100 * totalActualExpenses / totalPlannedExpenses) - 100;
	}

	const getUncategorizedPercentage = () => {
		if (totalUncategorizedExpenses == 0) return 0;
		if (totalPlannedExpenses == 0) return 0;
		return Math.round(100 * totalUncategorizedExpenses / totalPlannedExpenses);
	}

    return (
		<div>
			<Form.Group className="mb-3">
				<Form.Label>Звіт відносно:</Form.Label>
				<Form.Select value={summaryType} onChange={e => handleSummaryTypeChange(e) }>
					<option value={reportType.actualvsplanned}>Плану</option>
					<option value={reportType.actualvstotal}>Всіх витрат</option>
				</Form.Select>
			</Form.Group>
			
			{reportData !== null ? 
				(
					<div>

						{ /* TODO: Create Separate Component */ }
						<ListGroup className="items-list total-persentage">
							<ListGroup.Item key='lgitotal'>
								<ProgressBar>
									<ProgressBar variant="success" now={getPlanUsagePercentage()} key={1} label={`${getPlanUsagePercentage()}%`} />
									<ProgressBar variant="info" now={getUncategorizedPercentage()} key={2} label={`${getUncategorizedPercentage()}%`} />
									<ProgressBar variant="danger" now={getOverPlanUsagePercentage()} key={3} label={`${ getOverPlanUsagePercentage() }%`} />
								</ProgressBar>
							</ListGroup.Item>
						</ListGroup>
						<br />

						<ListGroup className="items-list">
							{reportData.map((group, index) => (
								<ListGroup.Item key={'g' + group.categoryId}>
									<div className="row">
										<div className="col-2">
											<i className={'text-center category-big-icon bi bi-' + categories.find(x => x.id === group.categoryId)?.icon}>&nbsp;</i>
										</div>
										<div className="col-10">
											<div className="row">
												<div className="col-7">
													<div>{group.label}</div>
												</div>
												<div className="col-5">
													<div className="expensesSummaryComment"><FormatedPrice price={group.expected} /> план</div>												
												</div>
											</div>
											<div className="row">
												<div className="col-7">
													<div><FormatedPrice price={group.actual} /></div>
												</div>
												<div className="col-5">
													<div className="expensesSummaryComment"><FormatedPrice price={group.expected - group.actual - Math.round(group.expected * group.toSavePercentNow / 100.0)} /> лишилось</div>
												</div>
											</div>
											<div className="row">
												<div className="col-12">
													{ summaryType == reportType.actualvsplanned &&
														(
														<ProgressBar>
															<ProgressBar variant="success" now={group.successPercentNow} key={1} label={`${group.successPercentNow}%`} />
															<ProgressBar variant="warning" now={group.toSavePercentNow} key={2} />
															<ProgressBar variant="danger" now={group.dangerPercentNow} key={3} label={`${group.dangerPercentNow > 0 ? (group.dangerPercentNow) + '%' : ''}`} />
														</ProgressBar>
														)
													}
													{ summaryType == reportType.actualvstotal &&
														(
														<ProgressBar>
															<ProgressBar variant="success" now={Math.round(100 * group.actual / totalActualExpenses)} max={50} key={1} label={`${Math.round(100 * group.actual / totalActualExpenses) }%`} />
														</ProgressBar>
														)
													}
												</div>
											</div>
										</div>
									</div>
								</ListGroup.Item>
							))}

							<ListGroup.Item key={'gsummary'}>
								<div className="row">
									<div className="col-6">
										<b>Витрачено</b>
									</div>
									<div className="col-3">
										
									</div>
									<div className="col-3">
										<b><FormatedPrice price={totalActualExpenses} /></b>
									</div>
								</div>
								<div className="row">
									<div className="col-6">
										<b>Залишилось</b>
									</div>
									<div className="col-3">

									</div>
									<div className="col-3">
										<b><FormatedPrice price={totalPlannedExpenses - totalActualExpenses} /></b>
									</div>
								</div>
								<div className="row">
									<div className="col-6">
										<b>Не пораховано</b>
									</div>
									<div className="col-3">

									</div>
									<div className="col-3">
										<b><FormatedPrice price={totalUncategorizedExpenses} /></b>
									</div>
								</div>
								<div className="row">
									<div className="col-6">
										Кешбек
									</div>
									<div className="col-3">
									
									</div>
									<div className="col-3">
										+{cashback}₴
									</div>
								</div>
							</ListGroup.Item>
						</ListGroup>
					</div>
                )
                : (<></>)
            }
        </div>
    );
});

export default ExpensesTable;