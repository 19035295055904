import { useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import ListGroup from 'react-bootstrap/ListGroup';
import CloseButton from 'react-bootstrap/CloseButton';
import { postData, postFormData, getData } from '../services/RequestWrapper';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';
import FormatedPrice from './CashFlow/FormatedPrice';

// https://github.com/react-icons/react-icons
import { BsFillPencilFill } from "react-icons/bs";

import ChoosePeriod from './ChoosePeriod';

import './Planner.css';

const Planner = forwardRef((props, ref) => {
	const [selectedPeriod, setSelectedPeriod] = useState(null);
	const [selectedPeriodLimit, setSelectedPeriodLimit] = useState(0);
	const [selectedPeriodId, setSelectedPeriodId] = useState(-1);
	const [categoryExpenses, setCategoryExpenses] = useState([]);
	const [prevPeriodCategoryExpenses, setPrevPeriodCategoryExpenses] = useState([]);
	const [prevPeriodReport, setPrevPeriodReport] = useState([]);
	const [expensesSum, setExpensesSum] = useState(0);
	const [categories, setCategories] = useState([]);

	const choosePeriodRef = useRef();
	const prevPeriodRef = useRef();

	const [showModalEdit, setShowModalEdit] = useState(false);
	const [editExpenseId, setEditExpenseId] = useState({});
	const [editExpenseCategory, setEditExpenseCategory] = useState(0);
	const [editExpenseCost, setEditExpenseCost] = useState('');
	const [editExpenseDescription, setEditExpenseDescription] = useState('');

	const [showModalAdd, setShowModalAdd] = useState(false);
	const [addExpenseCategory, setAddExpenseCategory] = useState(0);
	const [addExpenseCost, setAddExpenseCost] = useState('');
	const [addExpenseDescription, setAddExpenseDescription] = useState('');

	useEffect(() => {
		getData('/api/config/category/get/all').then(data => {
			setCategories(data);
			setAddExpenseCategory(data[0].id);
		});
	}, [])

	const handlePeriodChange = (p) => {
		setSelectedPeriod(p);
		setSelectedPeriodId(p.id);
		setSelectedPeriodLimit(p.limit);		
		handleReloadPeriod(p.id);
	};

	const handlePrevPeriodChange = (p) => {
		if (p.id <= 0) {
			setPrevPeriodCategoryExpenses([]);
		}
		postFormData('/api/config/expense/get/for/period', { period: p.id }).then(data => {
			setPrevPeriodCategoryExpenses(data);
		});

		postFormData('/api/report/plans/vs/actual/for/report', { periodId: p.id }).then(data => {
			setPrevPeriodReport(data);
		});
	};

	const handleReloadPeriod = (id) => {
		postFormData('/api/config/expense/get/for/period', { period: id }).then(data => {
			setCategoryExpenses(data);
			updateExpensesSum(data);
		});
	};

	const updateExpensesSum = (data) => {
		let sum = 0;
		data.map(c => {
			c.expenses.map(e => {
				sum += e.cost;
			});	
		});

		setExpensesSum(sum);
	}
	
	const handleAddNewExpense = () => {
		postData("/api/config/expense/add", { categoryId: addExpenseCategory, periodId: selectedPeriodId, cost: addExpenseCost, description: addExpenseDescription }).then(data => {
			const ce = categoryExpenses.map(c => {
				if (c.id == data.categoryId) c.expenses.push(data);
				return c;
			});

			setCategoryExpenses(ce);
			updateExpensesSum(ce);

			setAddExpenseCost('');
			setAddExpenseDescription('');
		});
	}

	const handleOpenEditDialog = (cIndex, eIndex) => {
		var expense = categoryExpenses[cIndex].expenses[eIndex];
		setEditExpenseId(expense.id);
		setEditExpenseCategory(expense.categoryId);
		setEditExpenseCost(expense.cost);
		setEditExpenseDescription(expense.description);
		setShowModalEdit(true);
	}

	const handleCloseEdit = () => {
		setShowModalEdit(false);
	}

	const handleSave = () => {
		postData('/api/config/expense/edit', { id: editExpenseId, categoryId: editExpenseCategory, periodId: selectedPeriodId, cost: editExpenseCost, description: editExpenseDescription }).then(data => {
			// TODO: reload only one expense
			handleReloadPeriod(selectedPeriodId);
			setShowModalEdit(false);
		});
	}

	const handleDelete = () => {
		const confirm = window.confirm("Видалити " + editExpenseDescription + "?");
		if (!confirm) return;

		postFormData("/api/config/expense/delete", { id: editExpenseId }).then(data => {
			// TODO: reload only one expense
			handleReloadPeriod(selectedPeriodId);
			setShowModalEdit(false);
		});
	}

	const handleCloseAdd = () => {
		setShowModalAdd(false);
	}

	const updatePeriodLimit = () => {
		postData('/api/config/period/edit', { id: selectedPeriod.id, startDate: selectedPeriod.startDate, endDate: selectedPeriod.endDate, description: selectedPeriod.description, limit: selectedPeriodLimit }).then(data => {
			setSelectedPeriod(data);
		});
	}

	// Reuse data from previous month
	const handleOpenAddDialogWithPopulatedData = (category, cost, description) => {
		setAddExpenseCategory(category);
		setAddExpenseCost(cost);
		setAddExpenseDescription(description);
		setShowModalAdd(true);
	}

	const getPlanProgressPercentage = () => {
		if (selectedPeriodLimit == 0) return 0;
		if (expensesSum > selectedPeriodLimit) return 100;
		return Math.round(100 * expensesSum / selectedPeriodLimit);
	}

	const getOverPlanProgressPercentage = () => {
		if (selectedPeriodLimit == 0) return 0;
		if (expensesSum < selectedPeriodLimit) return 0;
		return Math.round(100 * expensesSum / selectedPeriodLimit) - 100;
	}

    return (
		<div className='planner'>
			<div className="row">
				<div className="col-6">
					<Form.Group>
						<Form.Label>Поточний період</Form.Label>
						<ChoosePeriod ref={choosePeriodRef} onPeriodChanged={handlePeriodChange} />
					</Form.Group>
				</div>
				<div className="col-6">
					<Form.Group>
						<Form.Label>Порівняти з</Form.Label>
						<ChoosePeriod ref={prevPeriodRef} onPeriodChanged={handlePrevPeriodChange} loadPrevPerod={true} />
					</Form.Group>
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-md-12">
					<div className="row">
						<div className="col-6">
							<Form.Group className="mb-3">
								<Form.Label>Ліміт на період</Form.Label>
								<Form.Control type="number" value={selectedPeriodLimit} onChange={e => setSelectedPeriodLimit(e.target.value)} onBlur={updatePeriodLimit} />
							</Form.Group>
						</div>
						<div className="col-6">
							<Form.Label>Заплановано: <FormatedPrice price={expensesSum} /></Form.Label> <br />
							<Form.Label>Залишилось: <FormatedPrice price={selectedPeriodLimit - expensesSum} /></Form.Label>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<ProgressBar>
								<ProgressBar variant="success" now={getPlanProgressPercentage()} key={1} label={`${getPlanProgressPercentage()}%`} />
								<ProgressBar variant="danger" now={getOverPlanProgressPercentage()} key={2} label={`${getOverPlanProgressPercentage()}%`} />
							</ProgressBar>
							<br/>
						</div>
					</div>
				</div>
			</div>

			<ListGroup className="planner-list">
				{categoryExpenses.map((category, cIndex) => (
					<ListGroup.Item key={'c' + category.id} id={'cid' + category.id} >
						<div className="row">
							<div className="col-xs-12 col-md-12">
								<div className="row">
									<div className="col-10"><i className={'bi bi-' + category.icon}></i> {category.name}</div>
									<div className="col-2 d-flex justify-content-center">
										<FormatedPrice price={category.expenses.reduce((accumulator, object) => { return accumulator + object.cost; }, 0)} />
									</div>
								</div>								
								<div className="row">	
									<div className="col-12">
										<ul>
											{category.expenses.map((expense, eIndex) => (
												<li key={'c' + expense.id}>{expense.description}: <FormatedPrice price={expense.cost} /> <BsFillPencilFill className="editExpenseBtn" onClick={(e) => { e.preventDefault(); handleOpenEditDialog(cIndex, eIndex); }} /></li>
											))}
											<li>&nbsp;</li>
											{prevPeriodCategoryExpenses.find(e => e.id == category.id)?.expenses.map((expense, cIndex) => (
												<li className="prevPeriodExpense" key={'c' + expense.id} onClick={(e) => { handleOpenAddDialogWithPopulatedData(expense.categoryId, expense.cost, expense.description) }} >{expense.description}: <FormatedPrice price={expense.cost} /></li>
											))}
											<li className="prevPeriodExpense" key={'ppr' + category.id}>Всього витрачено: <FormatedPrice price={prevPeriodReport.find(e => e.categoryId == category.id)?.actual} /></li>
										</ul>
									</div>
								</div>
								
							</div>

							<div className="col-xs-12 col-md-6">
								<div className="row">
									<div className="col-12">
										
									</div>
								</div>
							</div>
						</div>
						
						
					</ListGroup.Item>
				))}
			</ListGroup>

			<Modal show={showModalEdit} onHide={handleCloseEdit}>
				<Modal.Header closeButton>
					<Modal.Title>Edit</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Категорія</Form.Label>
							<Form.Select className="" value={editExpenseCategory} onChange={e => setEditExpenseCategory(e.target.value)}>
								{categories.map((category, index) => (
									<option key={category.id} value={category.id}>{category.name}</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Витрата</Form.Label>
							<Form.Control placeholder="Витрата" aria-label="Edit Expense" type="text" value={editExpenseDescription} onChange={e => setEditExpenseDescription(e.target.value)} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Вартість</Form.Label>
							<Form.Control placeholder="Вартість" aria-label="Edit Expense" type="number" value={editExpenseCost} onChange={e => setEditExpenseCost(e.target.value)} />
						</Form.Group>
					</Form>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" onClick={handleDelete}>
						Delete
					</Button>
					<Button variant="primary" onClick={handleSave}>
						Edit
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showModalAdd} onHide={handleCloseAdd}>
				<Modal.Header closeButton>
					<Modal.Title>Add</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group className="mb-3">
							<Form.Label>Категорія</Form.Label>
							<Form.Select className="" value={addExpenseCategory} onChange={e => setAddExpenseCategory(e.target.value)}>
								{categories.map((category, index) => (
									<option key={category.id} value={category.id}>{category.name}</option>
								))}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Витрата</Form.Label>
							<Form.Control placeholder="Назва" aria-label="Add Expense" type="text" value={addExpenseDescription} onChange={e => setAddExpenseDescription(e.target.value)} />
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label>Вартість</Form.Label>
							<Form.Control placeholder="грн" aria-label="Add Expense" type="number" value={addExpenseCost} onChange={e => setAddExpenseCost(e.target.value)} />
						</Form.Group>
					</Form>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleAddNewExpense}>
						Add
					</Button>
				</Modal.Footer>
			</Modal>

			<Button variant="primary" size="lg" className="newExpenseBtn" active onClick={ e => setShowModalAdd(true) }>
				+
			</Button>

        </div>
    );
});

export default Planner;