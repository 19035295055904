import { useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react';

import Form from 'react-bootstrap/Form';
import { getData } from '../services/RequestWrapper';

const ChoosePeriod = forwardRef((props, ref) => {
    const [selectedPeriod, setSelectedPeriod] = useState(-1);
    const [periods, setPeriods] = useState([]);

    useImperativeHandle(ref, () => ({
        getPreviousPeriod(selectedPeriodId) {
            var index = periods.findIndex(e => e.id == selectedPeriodId);
            if (index >= 0) return periods[index + 1];
            return null;
        }
    }))


    useEffect(() => {
        getData('/api/config/period/get/all').then(data => {
            setPeriods(data);

            if (data.length > 0) {
                const dateNow = Date.parse(new Date().toJSON().slice(0, 10));
                var periodId = -1;

                for (var i = 0; i < data.length; i++) {
                    if (Date.parse(data[i].startDate) <= dateNow && dateNow <= Date.parse(data[i].endDate)) {
                        periodId = i;
                        break;
                    }
                }

                if (periodId >= 0 && props.loadPrevPerod) periodId++;

                props.onPeriodChanged(data[periodId]);
                setSelectedPeriod(data[periodId].id);
            }
        });
    }, [])

    const handleSelectPeriodChange = (e) => {
        setSelectedPeriod(e.target.value);
        props.onPeriodChanged(periods.find(x => x.id == e.target.value));
    };

    return (
        <div className='choose-period'>
            <Form.Select value={selectedPeriod} onChange={handleSelectPeriodChange}>
                {periods.map((period, index) => (
                    <option key={period.id} value={period.id}>{period.description + ": " + period.startDate + " - " + period.endDate}</option>
                ))}
            </Form.Select>

            <br/>
        </div>
    );
});

export default ChoosePeriod;